import React, { useEffect, useState } from "react";
// import { ethers, BigNumber } from "ethers";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import abi from "../abi.json";
import "./mint.css";
import Animation from "./Floating-NiFTis.gif";
import { useAccount } from "wagmi";
import {
    prepareWriteContract,
    waitForTransaction,
    writeContract,
  } from "@wagmi/core";
import toast from "react-hot-toast";


import Web3 from "web3";
const Mint = () => {
    const { address } = useAccount();
    const webSupply = new Web3("https://endpoints.omniatech.io/v1/bsc/mainnet/public");

    const niftiContract = "0x68Cf77b11B839b93Ae5C85DE27aa6C2e62b1Dd9A";
  const [spinner, setSpinner] = useState(false);

    const mintCID =
        "https://app-vw72p6khfq-uc.a.run.app/token/";

    const randgen = () => {
        const theArr = [];
        const times = 100;
        for (let i = 0; i <= times; i++) {
            if (i % 33 === 0) {
                theArr.push(Math.floor(Math.random() * (25 - 23 + 1) + 23));
                theArr.push(Math.floor(Math.random() * (22 - 16 + 1) + 16));
                theArr.push(Math.floor(Math.random() * (22 - 16 + 1) + 16));
            } else {
                theArr.push(Math.floor(Math.random() * 16));
            }
        }

        return theArr[Math.floor(Math.random() * 109)];
    };
    const morerand = () => {
        const arrMore = [];
        arrMore.push(`${mintCID}${randgen()}.json`);
        arrMore.push(`${mintCID}${randgen()}.json`);
        arrMore.push(`${mintCID}${randgen()}.json`);
        return arrMore;
    };
    const handleMint = async (num) => {

      
        console.log("hitting");
    


            
            // { value: ethers.utils.parseEther(`${fee}`) };
            // const provider = new ethers.providers.Web3Provider(window.ethereum);
            // const signer = provider.getSigner();
            // const contract = new ethers.Contract(niftiContract, abi, signer);
            try {


                if (address) {
                    let balanceOf = await webSupply.eth.getBalance(address);
                    balanceOf= webSupply.utils.toWei(balanceOf.toString());

        
        
                    let fee;
                    let cid;
                    if (num === 1) {
                        fee = 100000000000;
        
                        // cid = [`${mintCID}${randgen()}.json`];
                    } else {
                        fee = 25000000000000;
                        cid = morerand();
                    }
        
         if (Number(balanceOf) < Number(fee)) {
            toast.error("Insufficient balance");
            setSpinner(false);
          } else {
            setSpinner(true);
        
                        let valueFee = (fee).toLocaleString('fullwide', {useGrouping:false});
                        let amounts1=parseInt(valueFee)
                        valueFee=(amounts1).toLocaleString('fullwide', {useGrouping:false});


                console.log("response", num, cid, valueFee);
                const { request } = await prepareWriteContract({
                    address: niftiContract,
                    abi: abi,
                    functionName: "mintToken",
                    args: [num.toString()],
                    value:valueFee.toString(),
                    account: address,
                });
                const { hash } = await writeContract(request);
                const data = await waitForTransaction({
                    hash,
                });
                toast.success(`successful mint`);
            setSpinner(false);

            }}
            else{
            toast.error("Connect Wallet!");
            setSpinner(false); }
            }
            catch (error) {
            setSpinner(false);
                console.log("running");
                console.log("got error", error);
            }
        
    };
    return (
        <div style={{ position: "relative" }}>
            {/* <img className="animation" src={Animation} /> */}
            <div className="description_container">
                <h2>Mint Your Meme v1 NFT Cards</h2>
                <p>
                    Which meme card will you get? There is a total of 100 unique
                    V1 Memes out there. Mint, trade, collect and win!
                </p>
                <p>
                    1st, 2nd and 3rd place that can collect the 100 unique Meme
                    V1 NFT cards the fastest will win BiG!
                </p>
                <ul>
                    <li>
                        Mint 1 Meme v1 for 0.1 BNB or 3 Memes v1 for 0.25 BNB
                    </li>
                    <li>Must have necessary BNB amount in your wallet</li>
                </ul>
            </div>
            <div className="MintButtonContainer" style={{marginBottom:"10rem"}}>
                <button className="MintButton" onClick={() => handleMint(1)}>
                {" "}
                {spinner ? "Loading..." : "Mint 1 NFT"}{" "}
                </button>
                <button className="MintButton" onClick={() => handleMint(3)}>
                {" "}
                {spinner ? "Loading..." : "Mint 3 NFTs"}{" "}
                </button>
            </div>

        </div>
    );
};

export default Mint;
