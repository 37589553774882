import React, { useState } from "react";
import "./hero.css";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";

export default function Hero() {
  const { chain } = useNetwork();
  const { chains, switchNetwork } = useSwitchNetwork();
  const { address } = useAccount();
  const { open } = useWeb3Modal();
  // const [address, setaddress] = useState(0);
  // // const [account, setAccount] = useState();
  // const { sdk, connected, connecting, provider, chainId } = useSDK();
  // const connectWallet = async () => {
  //     console.log("got hit");
  //     try {
  //         const accounts = await sdk.connect("sign this");
  //         console.log({ accounts });
  //         setaddress(accounts?.[0]);
  //     } catch (err) {
  //         console.log("failed to connect..", err);
  //     }
  // };

  return (
    <div className="button_hero_container">
      {/* <button className="neon-button">View Nifti's Album</button> */}
      <button
        onClick={() =>
          address
            ? chain?.id == chains[0]?.id
              ? open()
              : switchNetwork?.(chains[0]?.id)
            : open()
        }
        className="neon-button"
      >
        {address ? (
          chain?.id == chains[0]?.id || chain?.id == chains[1]?.id ? (
            address ? (
              <>
                {`${address.substring(0, 6)}...${address.substring(
                  address.length - 4
                )}`}
              </>
            ) : (
              <>
                <span className="mx-1">Connect Wallet</span>
              </>
            )
          ) : (
            "Switch NetWork"
          )
        ) : (
          <>
            <span className="mx-1">Connect Wallet</span>
          </>
        )}
      </button>
    </div>
  );
}
