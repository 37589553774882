import React, { useState, useEffect } from "react";
import "./header.css";
import LOGO_ONE from "./icons/logo.png";
import Facebook from "./icons/facebook.png";
import Instagram from "./icons/instagram.png";
import Telegram from "./icons/telegram.png";
import Tiktok from "./icons/tiktok.png";
import Twitter from "./icons/twitter.png";
import Youtube from "./icons/youtube.png";
const Header = () => {
  const [imgAddress, setImage] = useState(LOGO_ONE);

  return (
    <>
      

      <div className="NavBar">
        <div className="container">
          <img src={imgAddress} width="auto" height="50px" />

          <div className="navbar_link">
            <a href="https://cr8rtoken.io/">Home</a>
            <a href="https://gamerules.cr8rtoken.io/">Game Rules</a>
            <a href="https://statsmemes1.cr8rtoken.io/">Stats Page</a>
          </div>
        </div>
      </div>
    </>
  );
};


export const CustomFooter = () => {
  return (
    <div className="footer">
      <div className="footer_container">
        <span>Copyright @ CR8R Token 2024</span>
        <div className="social_buttons_container">
          <a href="https://www.facebook.com/people/Teh-Creatorit/pfbid02YGV7DhTjM5AtvfWk1NpsRjpSVHxLW3JMnwvasNnKTtuTeFPiAgD3ph9RLTtW9dNcl/">
            <img src={Facebook} height="40px" width="40px" />
          </a>
          <a href="https://www.instagram.com/cr8rtoken/">
            <img src={Instagram} height="40px" width="40px" />
          </a>
          <a href="https://t.me/CR8R_Token">
            <img src={Telegram} height="40px" width="40px" />
          </a>
          <a href="https://www.tiktok.com/@cr8rtoken">
            <img src={Tiktok} height="40px" width="40px" />
          </a>
          <a href="https://twitter.com/cr8rtoken/">
            <img src={Twitter} height="40px" width="40px" />
          </a>
          <a href="https://www.youtube.com/@NFTCR8R">
            <img src={Youtube} height="40px" width="40px" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
